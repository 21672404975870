
import { Component } from 'vue-property-decorator';
import ClassworkMixin from '@/mixins/classwork-mixin';
import ClassworkToolbar from './ClassworkToolbar.vue';

  @Component({
    mixins: [ClassworkMixin],
    components: {
      ClassworkToolbar
    }
  })
export default class ClassworkBottomToolbar extends ClassworkToolbar {
}
